import type { ContentProductVariant } from '@aller/nuxt-allerservice-sdk/transformers';

/**
 * Convert price to localized (danish) format.
 *
 * @param { number } price
 * @returns { string|undefined }
 */
export function formatPrice(price?: number): string | undefined {
  return price?.toLocaleString('da-DK', { minimumFractionDigits: 2 });
}

/**
 * Find lowest product variant price.
 *
 * @param { ContentProductVariant[] } variants
 * @returns { string | undefined }
 */
export function findLowestProductVariantPrice(variants: ContentProductVariant[]): string | undefined {
  let lowestPrice: number | undefined;
  variants.forEach((variant) => {
    if (!variant.price.currentPrice) {
      return;
    }

    if (lowestPrice === undefined || variant.price.currentPrice < lowestPrice) {
      lowestPrice = variant.price.currentPrice;
    }
  });

  return formatPrice(lowestPrice);
}
